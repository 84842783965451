<template>
<div class="modal-content-pp">
    <ion-header class="headerTop">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon class="closebutton" src="assets/ICONOS/atras.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding contentt"> 
      <table class="mtable"  :class="array[0].suc ? 'modalMusicList' : ''">
        <tr>
          <th class="fecha">Fecha</th>
          <th class="hora">Hora</th>
          <th class="token">Token</th>
          <th class="category" v-show="array.length > 0 && array[0].category">Filtro</th>
          <th class="logFilter" v-show="array.length > 0 && array[0].logFilter">Formula</th>
          <th class="suc" v-show="array.length > 0 && array[0].suc">Suc</th>
          <th class="suc" v-show="array.length > 0 && array[0].suc">Cat. Musical</th>
        </tr>
        <tr v-for="item in array" :key="item">
          <td>{{ item.date }}</td>
          <td>{{ item.hora }}</td>
          <td>{{ item.token }}</td>
          <td v-show="item.category">{{ item.category }}</td>
          <td v-show="item.logFilter">{{ item.logFilter }}</td>
          <td v-show="item.suc">{{ item.suc }}</td>
          <td v-show="item.suc">{{ item.sucMusicCate }}</td>
        </tr>
      </table> 
    </ion-content>
</div>
  </template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'logListModal',
  props: {
    title: { type: String, default: 'Super Modal' },
    array: { type: Array, default: () => [] },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  methods: {
      closeModal() {
          modalController.dismiss({dismissed: true});
      }
  },
  setup() {
      return {close,};
  },
});
</script>

<style scoped>
.closebutton{
    color: #FFF;
}
.contentt   { 
    margin: 0 auto; 
    padding: 10px;  
    color: #000;  
    height: calc(100% - 60px);
}
.modal-content-pp {
    min-height: calc(100% - 30px);
    border: solid 1px #ccc;
}
.mtable {
    width: 100%;
    border-collapse: collapse;
}
.mtable th {  
    font-weight: bold;
    position: relative;
}
.mtable td, .mtable th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
.mtable tr:nth-child(even) {
    background-color: #111;
}
 
.mtable tr:hover {
    background-color: #333;
}
.mtable .token:hover:before {
    content: 'TOKEN: Orden de reproducción del día';
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    left: 0;
    
}
.modalMusicList tr td {
    padding: 2px;
    font-size: 12px;
}
</style>